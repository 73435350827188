<template>
  <div>
    <VueContentHolder class="content-wrapper" :padding="[20, 20, 0]" v-if="headerData">
      <div class="landing">
        <BrandLogo :width="60" :height="60" :image="partner.image" />
        <div class="right-area">
          <VueHeadline level="2" weightLevel="6" color="grey-40" class="headline-text">{{
            headerData.title
          }}</VueHeadline>
          <VueText sizeLevel="3" color="grey-30" class="text">
            <div>{{ headerData.description }}</div>
          </VueText>
        </div>
      </div>
      <VueContentHolder class="people-wrapper">
        <BrandMeetPerson
          v-if="headerData.salesManagerInfo"
          :hrefCall="headerData.salesManagerInfo.phone"
          :person="headerData.salesManagerInfo"
          :cornerIcon="icons.phoneAlt"
        />
      </VueContentHolder>
      <VueContentHolder class="people-wrapper">
        <BrandMeetPerson
          v-if="headerData.businessDeveleopmentManagerInfo"
          :hrefCall="headerData.businessDeveleopmentManagerInfo.phone"
          :person="headerData.businessDeveleopmentManagerInfo"
          :cornerIcon="icons.phoneAlt"
        />
      </VueContentHolder>
    </VueContentHolder>
    <div class="tabs">
      <div>
        <BrandButton
          @click="setActiveTab('visitDays')"
          shape="rounded"
          :radius="50"
          :size="sizes.xSmall"
          class="tab-btn"
          :outlined="activeTab !== 'visitDays'"
          ><VueText weightLevel="4" sizeLevel="3">ZİYARET GÜNLERİM</VueText></BrandButton
        >
      </div>
      <div>
        <BrandButton
          @click="setActiveTab('orderDays')"
          :size="sizes.xSmall"
          shape="rounded"
          :radius="50"
          :outlined="activeTab !== 'orderDays'"
          class="tab-btn"
          ><VueText weightLevel="4" sizeLevel="3">ÖN SİPARİŞ GÜNLERİM</VueText></BrandButton
        >
      </div>
    </div>
    <div class="days-table" v-for="(partner, i) in partners" :key="i">
      <div class="day" v-for="(day, index) in days" :key="index">
        <div class="day-name">{{ getDayName(day) }}</div>
        <div class="day-check">
          <VueIcon
            v-if="isChecked(day, partner)"
            :iconName="constants.icons.iconChecked.name"
            :width="constants.icons.iconChecked.width"
            :height="constants.icons.iconChecked.height"
          />
        </div>
      </div>
    </div>
    <div class="list-button">
      <router-link :to="{ path: productAndPriceListButton.path }" @click.native="dataLayerEvent()">
        <span class="pmi-menu-text">{{ productAndPriceListButton.text }}</span>
      </router-link>
    </div>
    <VueContentHolder class="content-wrapper" :padding="[20]">
      <VueHeadline class="products-headline" level="3" weightLevel="3">Kategoriler</VueHeadline>
      <div class="products-wrapper">
        <BrandProduct
          v-for="category in categories"
          :key="category.pmCode"
          :productName="category.name"
          :productPath="`ulker/${slugify(category.name)}/${category.id}`"
          :productImageSrc="category.image"
        ></BrandProduct>
      </div>
    </VueContentHolder>
  </div>
</template>

<script>
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
import dateUtils from '@/mixins/dateUtils';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import BrandMeetPerson from '@/components/brand/BrandMeetPerson/BrandMeetPerson.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import BrandProduct from '@/components/brand/BrandProduct/BrandProduct.vue';
import { Pladis } from '@/services/Api/index.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { User } from '@/services/Api/index';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import gtmUtils from '@/mixins/gtmUtils.js';
import { getPartnerImage } from '@/utils/partnerImageUtils';
import { BUSINESS_PARTNERS } from '@/constants/businessPartners.constants';
import { slugify } from '@/utils/stringUtils';
import { DAYS } from '@/constants/days.constants';

export default {
  name: 'Home',
  components: {
    BrandLogo,
    VueHeadline,
    VueText,
    BrandMeetPerson,
    BrandProduct,
    VueContentHolder,
    VueIcon,
    BrandButton,
  },
  mixins: [gtmUtils, dateUtils],
  data() {
    return {
      partner: {
        code: 'PLADIS',
        image: this.getPartnerImage(),
      },
      headContent: 'Ülker',
      headerData: null,
      productAndPriceListButton: {
        id: 0,
        text: 'Güncel Ürün ve Fiyat Listesi',
        icon: ICON_VARIABLES['sheetTable'],
        path: 'ulker/product-price-list',
      },
      categories: [],
      activeTab: 'visitDays',
      days: DAYS,
      partners: [],
    };
  },
  computed: {
    icons() {
      return ICON_VARIABLES;
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
        icons: ICON_VARIABLES,
      };
    },
  },
  mounted() {
    this.getCategories();
    this.getHeader();
  },
  created() {
    this.getRouteDays();
  },
  methods: {
    getPartnerImage() {
      return getPartnerImage('ulker');
    },
    dataLayerEvent() {
      this.pushDataLayerEvent('fiyat_listesi_click', {
        location: 'business-partners',
        partner: 'ÜLKER',
      });
    },
    getRouteDays() {
      User.getRouteDays()
        .then(res => {
          if (res.data && res.data.Data) {
            res.data.Data.forEach(f => {
              if (f.partnerName == BUSINESS_PARTNERS.ULKER.partnerName) this.partners = [f];
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    setActiveTab(tab) {
      if (this.activeTab != tab) {
        this.activeTab = tab;
      }
    },
    isChecked(day, partner) {
      if (this.activeTab === 'visitDays') {
        return partner?.routeDays.includes(day);
      } else {
        return partner?.routeOrderDays.includes(day);
      }
    },

    getCategories() {
      Pladis.getCategories(BUSINESS_PARTNERS.ULKER.partnerCode).then(response => {
        if (response.data && response.data.Data) {
          this.categories = response.data.Data;
        }
      });
    },
    getHeader() {
      Pladis.getHeader(BUSINESS_PARTNERS.ULKER.partnerCode).then(response => {
        if (response.data && response.data.Data) {
          let data = response.data.Data;
          this.headerData = data;
          if (data.salesManagerInfo) {
            this.headerData.salesManagerInfo = {
              name: data.salesManagerInfo.name
                ? data.salesManagerInfo.name.trim().toLocaleUpperCase('tr-TR')
                : '-',
              title: 'Satış Plasiyeri',
              phone: data.salesManagerInfo.phone_Number
                ? `+90${data.salesManagerInfo.phone_Number.trim()}`
                : '',
            };
          }
          if (data.businessDeveleopmentManagerInfo) {
            this.headerData.businessDeveleopmentManagerInfo = {
              name: data.businessDeveleopmentManagerInfo.name
                ? data.businessDeveleopmentManagerInfo.name.trim().toLocaleUpperCase('tr-TR')
                : '-',
              title: 'Satış Şefi',
              phone: data.businessDeveleopmentManagerInfo.phone_Number
                ? `+90${data.businessDeveleopmentManagerInfo.phone_Number.trim()}`
                : '',
            };
          }
        }
      });
    },
    slugify,
  },
};
</script>

<style scoped lang="scss">
.content-row {
  height: unset;
  padding-left: 20px;
}
.gray-bg {
  background: #f8f9f9;
  margin-bottom: 60px;
}
.landing {
  display: flex;
  padding-bottom: palette-space-level(15);
  .right-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 18px;

    .headline-text {
      margin-bottom: palette-space-level(10);
    }
    .text {
      line-height: 1.27;
      padding-right: palette-space-level(10);
    }

    .link-holder {
      display: flex;
      flex-direction: row;
      align-items: space-between;

      .left-link {
        padding-right: palette-space-level(30);
      }
      .left-link,
      .right-link {
        padding-top: palette-space-level(10);
        line-height: 1.27;
        text-decoration: underline;
      }
    }
  }
}
.people-wrapper {
  margin-top: 10px;
}
.list-button {
  margin-top: 20px;
  margin-left: 20px;
  display: block;
  height: 35px;
  width: max-content;
  line-height: 35px;
  padding: 0 20px;
  text-align: center;
  border-radius: palette-radius-level('15');
  background-image: linear-gradient(to left, #d20051, #ffa300);
  margin-right: 15px;
  text-decoration: none;
  color: #ffffff;
  a {
    color: #ffffff;
    font-size: 14px;
    text-decoration: none;
  }
}
.icon-style {
  display: inline-block;
  position: relative;
  left: -10px;
}
.grid-row {
  gap: 20px;
}
.products-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 21px;
  grid-row-gap: 40px;
  /deep/ .product-img {
    padding: 10px;
  }
}
.products-headline {
  padding: 0 0 10px 0;
}
.products-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: palette-space-level('20');
  margin-bottom: 40px;
}
.tabs {
  display: flex;
  justify-content: center;
  margin-top: palette-space-level(25);
  z-index: 5;
  & > div {
    &:first-child {
      margin-right: palette-space-level(15);
      @media screen and (max-width: 325px) {
        margin-right: palette-space-level(10);
      }
    }
  }
  .tab-btn {
    padding: 5px 10px;
  }
}

.days-table {
  margin-top: palette-space-level(20);
  margin-left: palette-space-level(20);
  margin-right: palette-space-level(20);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  > div {
    &:first-child {
      border-left: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    }
  }
}
.day {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-top: 1px solid transparentize(palette-color-level('grey', 20), 0.5);

  &-name {
    background-color: palette-color-level('grey', 10);
    height: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    border-right: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    color: palette-color-level('grey', 30);
  }
  &-check {
    height: 101px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    border-right: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
  }
}
.info-text {
  padding-left: palette-space-level(20);
  padding-right: palette-space-level(20);
}
.weekly-wrapper {
  margin-bottom: palette-space-level(50);
}
</style>
